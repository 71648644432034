<template>
  <div>
    <form @submit.stop.prevent="handleSubmit" v-if="!wasSaved">
      <p class="lead">
        Salve
        <span v-if="favorable_type == 'Match'">esta partida</span>
        <span v-if="favorable_type == 'MatchRound'">este round</span>
      </p>

      <b-form-group
        label="Nome"
        description="ex: qual o ponto chave deste item?"
      >
        <b-form-input
          type="text"
          placeholder="informe um nome para identificar na Collection"
          v-model="favorite.name"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="Descrição"
      >
        <b-form-textarea type="text" placeholder="agora sim, informe mais detalhes sobre este item :)" v-model="favorite.description"></b-form-textarea>
      </b-form-group>

      <b-form-group
        label="Salvar em qual Collection?"
      >
        <b-form-select
          :options="collections"
          value-field="id"
          text-field="name"
          required
          placeholder="selecione uma collection"
          v-model="favorite.collection"
        ></b-form-select>
        <p>
          <small>
            <router-link :to="{ name: 'CollectionList' }">criar collection</router-link>
          </small>
        </p>
      </b-form-group>

      <b-btn type="submit" variant="primary">criar</b-btn>
    </form>

    <div v-else>
      <h4>adicionado com sucesso ;)</h4>
      <p class="lead">pode fechar essa aba</p>
    </div>

  </div>
</template>

<script>
export default {
  name: 'CollectionModal',
  props: {
    resource: Object,
    favorable_type: String,
    favorable_id: String,
  },
  data() {
    return {
      collections: [],
      wasSaved: false,
      favorite: {
        name: "",
        description: "",
        favorable_id: "",
        favorable_type: "",
        collection: ""
      },
    }
  },
  methods: {
    handleSubmit() {
      this.$http
        .post(
          `collections/${this.favorite.collection}/collection_favorites.json`,
          this.favorite
        )
        .then(response => {
          this.wasSaved = true
        });
    }
  },
  mounted() {
    this.favorite.favorable_id = this.favorable_id
    this.favorite.favorable_type = this.favorable_type
    if (this.isLoggedIn)
      this.$http
        .get("collections.json")
        .then(response => (this.collections = response.data));
  }
}
</script>
